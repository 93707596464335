import React from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import { getIcon } from '../../utils/icons-util';

function Contact() {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="text-xl font-bold title-with-line">
        {t('contact.title').toUpperCase()}
      </h1>
      {(t('common:contact.items', { returnObjects: true }) as [])
        .filter((item: any) => item.showResume)
        .map((item: any) =>
          item.href ? (
            <p key={item.icon} className="text-sm py-0.5">
              <a
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <img
                  className="h-5 w-5"
                  src={getIcon(item.icon)}
                  alt={item.icon}
                />
                &nbsp;&nbsp;{item.value}
              </a>
            </p>
          ) : (
            <p key={item.icon} className="text-sm py-0.5 flex items-center">
              <img
                className="h-5 w-5"
                src={getIcon(item.icon)}
                alt={item.icon}
              />
              &nbsp;&nbsp;{item.value}
            </p>
          )
        )}
    </>
  );
}

export default Contact;
