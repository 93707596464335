import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import { getIcon } from '../utils/icons-util';
import ProgressBar from './ProgressBar';

function Skills() {
  const { t } = useTranslation();
  const [skills, setSkills] = useState({} as any);

  useEffect(() => {
    setSkills(t('skills.items', { returnObjects: true }) as []);
  }, [t]);

  return (
    <div className="container mx-auto">
      <h1
        className="font-semibold text-5xl mb-12 text-center"
        data-aos="fade-up"
        dangerouslySetInnerHTML={{ __html: t('skills.title') }}
      ></h1>

      {(t('common:skills.items', { returnObjects: true }) as [])?.map(
        (item: any, index: number) => (
          <div
            key={index}
            className="grid grid-cols-1 sm:grid-cols-5 gap-2 mb-20"
          >
            <div
              className={`flex flex-col m-4 col-span-1 sm:col-span-2 ${
                index % 2 !== 0 && 'sm:order-last'
              }`}
            >
              <h2
                className="font-semibold text-3xl"
                data-aos="fade-up"
                dangerouslySetInnerHTML={{ __html: skills[item.id]?.title }}
              />
              {skills[item.id]?.description && (
                <p
                  data-aos="fade-up"
                  dangerouslySetInnerHTML={{
                    __html: skills[item.id]?.description,
                  }}
                />
              )}
              <div className="effects h-full w-full pattern-diagonal-lines-sm text-gray-200 mt-10" />
            </div>
            <div className="flex flex-wrap col-span-1 sm:col-span-3 items-center">
              {item.items.map((item2: any) => (
                <div
                  key={item2.icon}
                  className="flex flex-col items-center m-4 w-full sm:w-5/12 justify-center"
                  data-aos="fade-up"
                >
                  <div className="inline-flex items-center font-semibold mb-4 h-10">
                    {getIcon(item2.icon) && (
                      <img
                        className="w-10 mr-4"
                        src={getIcon(item2.icon)}
                        alt={item2.icon}
                      />
                    )}
                    {item2.name}
                  </div>
                  <ProgressBar scale={item2.scale} />
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default Skills;
