import React from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import { getIcon } from '../utils/icons-util';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="banner w-full my-20">
      <div className="container mx-auto px-4 mt-10">
        <div className="flex flex-wrap justify-center">
          <div className="w-72 h-72 shadow-lg border border-gray-200 rounded-full overflow-hidden">
            <img
              src={t('common:profile.img')}
              alt="banner"
              className="h-full w-full"
              data-aos="fade-up"
            />
          </div>
          <div className="w-full md:w-1/2 ml-0 md:ml-8 mt-4 md:mt-0 text-center md:text-left self-center">
            <h1
              className="font-bold text-5xl mb-6"
              data-aos="fade-up"
              dangerouslySetInnerHTML={{ __html: t('profile.title') }}
            ></h1>
            <p
              data-aos="fade-up"
              dangerouslySetInnerHTML={{ __html: t('profile.description') }}
            ></p>
            <div data-aos="fade-up" className="self-end">
              <ul className="inline-flex flex-row mt-4 space-x-2 list-none pl-0">
                {(t('common:contact.items', { returnObjects: true }) as [])?.map(
                  (item: any) =>
                    item.showPortfolio && (
                      <li
                        key={item.iconColor}
                        data-toggle="tooltip"
                        title={item.iconColor}
                      >
                        <a
                          href={item.href}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center justify-center h-8 w-8"
                        >
                          <img
                            src={getIcon(item.iconColor)}
                            alt={item.iconColor}
                          />
                        </a>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
