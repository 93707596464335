import React from 'react';
import { useTranslation } from 'react-i18next';

// Brands
import { getBrand } from '../utils/icons-util';

function Brands() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto text-center">
      <h1
        className="font-semibold text-5xl mb-12 text-center"
        data-aos="fade-up"
        dangerouslySetInnerHTML={{ __html: t('brands.title') }}
      />
      <p
        className="text-2xl"
        data-aos="fade-up"
        dangerouslySetInnerHTML={{ __html: t('brands.description') }}
      />
      <p
        className="text-xs"
        data-aos="fade-up"
        dangerouslySetInnerHTML={{ __html: t('brands.note') }}
      />
      <div className="card-stack w-full grid gap-4 grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 mt-10">
        {(t('common:brands.items', { returnObjects: true }) as [])?.map(
          (item: any, index: number) => (
            <a
              key={index}
              href={item.url}
              target="_blank"
              rel="noreferrer"
              data-aos="fade-up"
              className="stack-card grayscale-card dark:opacity-100 flex items-center justify-center px-10 bg-white dark:bg-darkBg border border-subtitle hover:border-subtitle dark:border-hoverCard dark:hover:border-subtitle rounded-lg shadow-none hover:shadow-lg transition ease-bounce duration-300 z-0 hover:z-50 transform scale-100 hover:scale-105 py-4 viewport-animation-card"
            >
              <img
                className="h-16 m-0"
                src={getBrand(item.logo)}
                alt={item.logo}
              />
            </a>
          )
        )}
      </div>
    </div>
  );
}

export default Brands;
