import i18n from 'i18next';
import translation_en from './en.json';
import translation_es from './es.json';
import common from './common.json';
import { initReactI18next } from 'react-i18next';

// Flags
import enFLag from '../assets/icons/langs/en.svg';
import esFLag from '../assets/icons/langs/es.svg';

export const resources = {
  en: {
    translation: translation_en,
    common,
    options: {
      flag: enFLag
    },
  },
  es: {
    translation: translation_es,
    common,
    options: {
      flag: esFLag
    },
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'en',
  resources,
});
