import React from 'react';

type Props = {
  scale: number;
};

function ProgressBar({ scale }: Props) {
  return (
    <div className="w-full overflow-hidden h-4 mb-4 text-xs flex rounded bg-gray-200">
      <div
        style={{ width: `${scale * 20}%` }}
        className="shadow-none flex flex-col text-center whitespace-nowrap text-white bg-secondary"
      >{`${scale * 20}%`}</div>
    </div>
  );
}

export default ProgressBar;
