import React from 'react';
import { useTranslation } from 'react-i18next';

function Profile() {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      <div className="inline-flex w-40 h-40 border-2 border-solid border-white rounded-full overflow-hidden mb-4">
        <img src={t('common:profile.img')} alt="banner" className="h-full w-full" />
      </div>
      <p className="font-bold mb-2">{t('common:profile.name')}</p>
      <p className="text-sm">{t('profile.description')}</p>
    </div>
  );
}

export default Profile;
