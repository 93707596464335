import React from 'react';
import Contact from 'src/components/resume/Contact';

// Components
import Profile from 'src/components/resume/Profile';
import Skills from 'src/components/resume/Skills';

function Resume() {

  return (
    <div className="container mx-auto border-solid border-gray-200 border text-base print:text-sm">
      <div className="grid grid-cols-1 print:grid-cols-3 sm:grid-cols-4 sm:my-4">
        <div className="text-white col-span-1 py-4 px-8 print:px-4" style={{background: '#2b3a50'}}>
          <Profile />
          <Contact />
          <Skills />
        </div>
        <div className="bg-white col-span-1 print:col-span-2 sm:col-span-3">B</div>
      </div>
    </div>
  );
}

export default Resume;
