import React from 'react';
import { useTranslation } from 'react-i18next';

// Icons
import { getIcon } from '../utils/icons-util';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="w-full bg-gray-800 text-white print:hidden">
      <div className="container mx-auto px-4 py-8 text-center">
        <div className="lg:mr-auto mb-10 lg:mb-0 ">
          <div className="mb-2 text-white">{t('contact.title2')}</div>
          {/* <Contact /> */}

          <ul className="inline-flex flex-row mt-4 space-x-2 list-none pl-0">
            {(t('common:contact.items', { returnObjects: true }) as [])?.map(
              (item: any) =>
                item.showPortfolio && (
                  <li key={item.icon} data-toggle="tooltip" title={item.icon}>
                    <a
                      href={item.href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center h-5 w-5"
                    >
                      <img src={getIcon(item.icon)} alt={item.icon} />
                    </a>
                  </li>
                )
            )}
          </ul>
        </div>

        <hr className="my-4" />
        <div className="text-center">
          {t('common:profile.name')} | {t('profile.description')}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
