import React from 'react';
import { useTranslation } from 'react-i18next';

function Projects() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto">
      <h1
        className="font-semibold text-5xl mb-12 text-center"
        data-aos="fade-up"
        dangerouslySetInnerHTML={{ __html: t('projects.title') }}
      />
      <div className="flex flex-wrap justify-center">
        {(t('projects.items', { returnObjects: true }) as [])?.map((element: any, index: number) => (
          <a
            href={element.url}
            target="_blank"
            rel="noreferrer"
            className="w-full sm:w-1/3 p-2"
            style={{ color: 'currentColor' }}
            key={index}
            data-aos="fade-up"
          >
            <div className={`card h-full bg-white`}>
              <p className="text-xl font-bold m-4 text-center">{element.name}</p>
              <img
                className="w-full border-b-2 border-gray-100 border-solid p-2"
                src={element.img}
                alt={element.name}
                title={element.name}
              />
              <div className="card-body">
                <p>{element.description}</p>
              </div>
              <div className="card-footer mb-2">
                <div className="badge-inline">
                  {element.technologies?.map((tag: string, index: number) => (
                    <span
                      key={`index_${index}`}
                      className="badge badge-pill bg-gray-300"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default Projects;
