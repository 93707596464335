import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../ProgressBar';
import RatingScale from '../RatingScale';

function Skills() {
  const { t } = useTranslation();
  const [skills, setSkills] = useState({} as any);

  useEffect(() => {
    setSkills(t('skills.items', { returnObjects: true }) as []);
  }, [t]);

  return (
    <>
      <h1 className="text-xl font-bold title-with-line">
        {t('skills.titleResume').toUpperCase()}
      </h1>
      {(t('common:skills.items', { returnObjects: true }) as []).map(
        (item: any, index: number) => (
          <div key={index} className="mb-4">
            <p
              className="font-bold"
              dangerouslySetInnerHTML={{ __html: skills[item.id]?.title }}
            />
            {item.items.map((item2: any) => (
              <div key={item2.icon} className="grid grid-cols-2 gap-2 text-sm">
                {/* <div>•&nbsp;&nbsp;&nbsp;{`${item2.name}`}</div> */}
                <div>•<span className="pl-3.5">{item2.name}</span></div>
                <RatingScale scale={item2.scale} />
              </div>
            ))}
            {/* <ul className="list-outside list-disc">
              {item.items.map((item2: any) => (
                <li key={item2.icon}>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>{item2.name}</div>
                    <RatingScale scale={item2.scale} />
                  </div>
                </li>
              ))}
            </ul> */}
          </div>
        )
      )}
    </>
  );
}

export default Skills;
