import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
// import { NavHashLink } from 'react-router-hash-link';
import './Header.scss';

const Header = () => {
  const [navbarIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <header className="header print:hidden">
      <nav className="navbar bg-gray-800 text-white">
        <div className="navbar-brand">{t('title.portfolio')}</div>
        <div
          className={
            'w-full block flex-grow lg:flex lg:items-center lg:w-auto ' +
            (!navbarIsOpen && 'hidden')
          }
        ></div>

        {/* <div className="flex space-x-4">
          <NavHashLink to="/" className="flex-1" activeClassName="active">
            {t('menu.home')}
          </NavHashLink>
          <NavHashLink to="/#about" className="flex-1" activeClassName="active">
            {t('menu.about')}
          </NavHashLink>
          <NavHashLink
            to="/#skills"
            className="flex-1"
            activeClassName="active"
          >
            {t('menu.skills')}
          </NavHashLink>
          <NavHashLink
            to="/#projects"
            className="flex-1"
            activeClassName="active"
          >
            {t('menu.projects')}
          </NavHashLink>
          <NavHashLink
            to="/#brands"
            className="flex-1"
            activeClassName="active"
          >
            {t('menu.brands')}
          </NavHashLink>
          <Link to="/resume" className="flex-1">
            {t('menu.resume')}
          </Link>
        </div> */}
        <LanguageSelector />
      </nav>
    </header>
  );
};

export default Header;
