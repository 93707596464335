import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Resume from 'src/pages/Resume';

// Components
import Header from '../components/Header';
import Footer from '../components/Footer';

// Pages
import Home from '../pages/Home';
// const Blog = React.lazy(() => {
//   return import('../pages/Blog');
// });

export const AppRoutes = () => (
  <Router>
    <Header />
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/resume" component={Resume} />
      <Route path="/:lang" component={Home} />
      <Route path="/resume/:lang" component={Resume} />
      <Route
        component={() => (
          <div>
            <h1>Error 404</h1>
            <span>Página no encontrada</span>
          </div>
        )}
      />
    </Switch>
    <Footer />
  </Router>
);
