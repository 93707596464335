import React, { Suspense, useEffect } from 'react';
import { AppRoutes } from './routes/AppRoutes';

import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {
useEffect(() => {
  AOS.init({ 
    duration: 700,
    once: true
  });
}, [])


  return (
    <Suspense fallback={<h1>Cargando...</h1>}>
      <AppRoutes />
    </Suspense>
  );
}

export default App;
