import React from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

type Props = {
  scale: number;
};

function RatingScale({ scale }: Props) {
  return (
    <div className="flex">
      {[1, 2, 3, 4, 5].map((element: any) =>
        element <= scale ? (
          <AiFillStar className="mx-0.5" />
        ) : (
          <AiOutlineStar className="mx-0.5" />
        )
      )}
    </div>
  );
}

export default RatingScale;
