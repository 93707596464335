// Icons Contact
import facebook from '../assets/icons/facebook-rounded.svg';
import facebookMono from '../assets/icons/facebook-mono.svg';
import github from '../assets/icons/github-rounded.svg';
import githubMono from '../assets/icons/github-mono.svg';
import instagram from '../assets/icons/instagram-rounded.svg';
import instagramMono from '../assets/icons/instagram-mono.svg';
import linkedin from '../assets/icons/linkedin-rounded.svg';
import linkedinMono from '../assets/icons/linkedin-mono.svg';
import linkMono from '../assets/icons/link-mono.svg';
import locationMono from '../assets/icons/location-mono.svg';
import mail from '../assets/icons/mail-rounded.svg';
import mailMono from '../assets/icons/mail-mono.svg';
import mobileMono from '../assets/icons/mobile-mono.svg';
import skypeMono from '../assets/icons/skype-mono.svg';
import tiktokMono from '../assets/icons/tiktok-mono.svg';
import twitch from '../assets/icons/twitch-rounded.svg';
import twitter from '../assets/icons/twitter-rounded.svg';
import twitterMono from '../assets/icons/twitter-mono.svg';
import whatsapp from '../assets/icons/whatsapp-rounded.svg';
import whatsappMono from '../assets/icons/whatsapp-mono.svg';
import youtube from '../assets/icons/youtube-rounded.svg';
import youtubeMono from '../assets/icons/youtube-mono.svg';
// import none from '../assets/icons/none.svg';

// Icons Technologies
import angular from '../assets/icons/angular.svg';
import aws from '../assets/icons/aws.svg';
import bootstrap4 from '../assets/icons/bootstrap-4.svg';
import bootstrap5 from '../assets/icons/bootstrap-5.svg';
import css3 from '../assets/icons/css3.svg';
import docker from '../assets/icons/docker.svg';
import dockerAlone from '../assets/icons/docker-alone.svg';
import expressjs from '../assets/icons/expressjs.svg';
import git from '../assets/icons/git.svg';
import gitAlone from '../assets/icons/git-alone.svg';
import graphql from '../assets/icons/graphql.svg';
import html5 from '../assets/icons/html5.svg';
import java from '../assets/icons/java.svg';
import javascript from '../assets/icons/javascript.svg';
import kubernets from '../assets/icons/kubernets.svg';
import mongodb from '../assets/icons/mongodb.svg';
import mongodbAlone from '../assets/icons/mongodb-alone.svg';
import mysql from '../assets/icons/mysql.svg';
import nodejs from '../assets/icons/nodejs.svg';
import nodejsAlone from '../assets/icons/nodejs-alone.svg';
import npm from '../assets/icons/npm.svg';
import oracle from '../assets/icons/oracle.svg';
import postgresql from '../assets/icons/postgresql.svg';
import react from '../assets/icons/react.svg';
import redux from '../assets/icons/redux.svg';
import sass from '../assets/icons/sass.svg';
import scrum from '../assets/icons/scrum.svg';
import tailwindcss from '../assets/icons/tailwindcss.svg';
import typescript from '../assets/icons/typescript.svg';
import vercel from '../assets/icons/vercel.svg';
import vue from '../assets/icons/vue.svg';

// Brands
import bancolombia from '../assets/brands/bancolombia.svg';
import copaAirlines from '../assets/brands/copa-airlines.svg';
import exito from '../assets/brands/exito.svg';
import theGlutenFreeFactory from '../assets/brands/the-gluten-free-factory.svg';
import grupoAval from '../assets/brands/grupo-aval.svg';
import safefleet from '../assets/brands/safefleet.svg';
import sufi from '../assets/brands/sufi.svg';
import sura from '../assets/brands/sura.svg';

let iconsMap: Map<string, any>;
let brandsMap: Map<string, any>;

export const getIcon = (name: string) => {
  if (!iconsMap) {
    iconsMap = new Map();
    iconsMap.set('facebook', facebook);
    iconsMap.set('facebook-mono', facebookMono);
    iconsMap.set('github', github);
    iconsMap.set('github-mono', githubMono);
    iconsMap.set('instagram', instagram);
    iconsMap.set('instagram-mono', instagramMono);
    iconsMap.set('link-mono', linkMono);
    iconsMap.set('linkedin', linkedin);
    iconsMap.set('linkedin-mono', linkedinMono);
    iconsMap.set('location-mono', locationMono);
    iconsMap.set('mail', mail);
    iconsMap.set('mail-mono', mailMono);
    iconsMap.set('mobile-mono', mobileMono);
    iconsMap.set('skype-mono', skypeMono);
    iconsMap.set('tiktok-mono', tiktokMono);
    iconsMap.set('twitch', twitch);
    iconsMap.set('twitter', twitter);
    iconsMap.set('twitter-mono', twitterMono);
    iconsMap.set('whatsapp', whatsapp);
    iconsMap.set('whatsapp-mono', whatsappMono);
    iconsMap.set('youtube', youtube);
    iconsMap.set('youtube-mono', youtubeMono);

    // Technologies
    iconsMap.set('angular', angular);
    iconsMap.set('aws', aws);
    iconsMap.set('bootstrap4', bootstrap4);
    iconsMap.set('bootstrap5', bootstrap5);
    iconsMap.set('css3', css3);
    iconsMap.set('docker', docker);
    iconsMap.set('docker-alone', dockerAlone);
    iconsMap.set('expressjs', expressjs);
    iconsMap.set('git', git);
    iconsMap.set('git-alone', gitAlone);
    iconsMap.set('graphql', graphql);
    iconsMap.set('html5', html5);
    iconsMap.set('java', java);
    iconsMap.set('javascript', javascript);
    iconsMap.set('kubernets', kubernets);
    iconsMap.set('mongodb', mongodb);
    iconsMap.set('mongodb-alone', mongodbAlone);
    iconsMap.set('mysql', mysql);
    iconsMap.set('nodejs', nodejs);
    iconsMap.set('nodejs-alone', nodejsAlone);
    iconsMap.set('npm', npm);
    iconsMap.set('oracle', oracle);
    iconsMap.set('postgresql', postgresql);
    iconsMap.set('react', react);
    iconsMap.set('redux', redux);
    iconsMap.set('sass', sass);
    iconsMap.set('scrum', scrum);
    iconsMap.set('tailwindcss', tailwindcss);
    iconsMap.set('typescript', typescript);
    iconsMap.set('vercel', vercel);
    iconsMap.set('vue', vue);
  }
  return iconsMap.get(name);
};

export const getBrand = (name: string) => {
  if (!brandsMap) {
    brandsMap = new Map();
    brandsMap.set('bancolombia', bancolombia);
    brandsMap.set('copa-airlines', copaAirlines);
    brandsMap.set('exito', exito);
    brandsMap.set('the-gluten-free-factory', theGlutenFreeFactory);
    brandsMap.set('grupo-aval', grupoAval);
    brandsMap.set('safefleet', safefleet);
    brandsMap.set('sufi', sufi);
    brandsMap.set('sura', sura);
  }
  return brandsMap.get(name);
};
