import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Home.module.scss';
import { resources as languages } from '../i18n/config';

// Components
import Banner from '../components/Banner';
import Skills from 'src/components/Skills';
import Projects from 'src/components/Projects';
import Brands from 'src/components/Brands';

const Home = () => {
  const params = useParams();
  const location = useLocation()
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (languages[params.lang]) {
      i18n.changeLanguage(params.lang);
    }
  }, [i18n, params.lang])

  return (
    <div className={`${styles.home} px-2`}>
      <section id="banner" className="py-28">
        <Banner />
      </section>

      <section id="about" className="py-28">
        <div className="container mx-auto text-center">
          <h1
            className="font-semibold text-5xl mb-12"
            data-aos="fade-up"
            dangerouslySetInnerHTML={{ __html: t('aboutMe.title') }}
          ></h1>
          <p
            data-aos="fade-up"
            dangerouslySetInnerHTML={{ __html: t('aboutMe.description') }}
          ></p>
        </div>
      </section>

      <section id="skills" className="py-28">
        <Skills />
      </section>

      <section id="projects" className="py-28">
        <Projects />
      </section>

      <section id="brands" className="py-28">
        <Brands />
      </section>
    </div>
  );
};

export default Home;
