import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { resources as languages } from '../i18n/config';

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  return (
    <div className="inline-block text-left">
      <button
        type="button"
        className="flex items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img className="w-8" src={t('options:flag')} alt="flag" />
        <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1 text-current" role="none">
            {
              Object.keys(languages).map((key, index) => (
                <div key={index} className="inline-block text-left w-full">
                  <button
                    type="button"
                    className="flex items-center focus:outline-none w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => changeLanguage(key)}
                  >
                    <img className="w-8" src={languages[key].options.flag} alt="flag" />
                    &nbsp;&nbsp;{t(`options.languages.${key}`)}
                  </button>
                </div>
              ))
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
